import { ref } from 'vue'
import openId from '../helper/openId.ts'
import { AuthenticationType } from '../types/State.ts'
import { authentication } from './backend/index.ts'
import { token as tokenAuth, startup as startupAuth } from './stateFunc.ts'
import { parseQuery, stringifyQuery } from 'vue-router'

export const stateInitLoading = ref(true)
export const stateInit = (async (): Promise<void> => {
  try {
    const query = parseQuery(window.location.search)
    const token = typeof query.token === 'string' ? query.token : null
    const access = typeof query.access === 'string' ? query.access : null
    const refresh = typeof query.refresh === 'string' ? query.refresh : null
    const code = typeof query.code === 'string' ? query.code : null
    const state = typeof query.state === 'string' ? query.state : null
    const userId = state?.split(';', 2)[1] ?? (typeof query.userid === 'string' ? query.userid : null)
    const username = typeof query.username === 'string' ? query.username : null

    if (code !== null && state !== null) await openId?.signinCallback()
    if (username !== null) {
      await openId?.removeUser()
      await openId?.signinRedirect({ login_hint: username, url_state: userId?.toString() })
    }

    const user = await openId?.getUser()

    if (Object.hasOwn(query, 'token') || Object.hasOwn(query, 'access') || Object.hasOwn(query, 'refresh')) {
      delete query.token
      delete query.access
      delete query.refresh
      const search = stringifyQuery(query)
      window.history.replaceState({}, '', window.location.pathname + (search.length > 0 ? '?' : '') + search)
    } else if (token !== null || window.location.pathname.endsWith('/.htm')) {
      console.info('token - ignore existing login, enable plain auth')
      authentication.type = AuthenticationType.Plain
      if (token === null) throw new Error('no token, but /.htm')
      else await tokenAuth({ token })
    } else if (access !== null && refresh !== null) {
      console.info('access/refresh - ignore existing login, enable plain auth')
      authentication.type = AuthenticationType.Plain
      authentication.access = access
      authentication.refresh = refresh
      authentication.userId = userId
      await startupAuth()
    } else if (user != null && !user.expired) {
      console.info('openid - using access token from identity provider', user)
      authentication.type = AuthenticationType.OpenID
      authentication.access = user.access_token ?? null
      authentication.refresh = user.refresh_token ?? null
      authentication.userId = userId ?? user.session_state ?? null
      document.cookie = `access=${user.access_token}; path=/; userid=${userId}`
      if (userId) {
        user.session_state = userId
        await openId?.storeUser(user)
      }
      await startupAuth()
    } else {
      console.info('legacy')
      authentication.type = AuthenticationType.Legacy
      await startupAuth()
    }
    console.info('success - active login found')
  } catch (e) {
    console.info('failure - no active login', e)
  } finally {
    stateInitLoading.value = false
  }
})()
